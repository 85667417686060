import React from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';

import { useTranslation } from '@external/react-i18next';

const DiscontinuedPageComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <OneColumn>
      <div className="mt-10 mobile-l:mt-20 mb-52 mobile-l:mb-56 desktop:mb-64 mx-0 p-0">
        <h1 className="text-xl-38 my-0 py-0 mobile-l:text-xl-48 desktop:text-xl-58 font-semibold leading-lg-45 mobile-l:leading-xl-57 desktop:leading-xl-70 text-naturals-gray-600 w-4/5 mobile-l:w-full">
          {t('page.discontinued.page-title', 'Page discontinued')}
        </h1>
        <div className="text-naturals-gray-600 mt-6 desktop:mt-8 display:flex display:flex-col text-naturals-gray-600 w-11/12 mobile-l:w-9/12 tablet:w-7/12 desktop:w-1/2">
          <p className="text-md leading-md">
            {t(
              'page.discontinued.page-description-1',
              'We have decided to discontinue “My Discussion Groups,” “My Messages,” “My Connections,” and “Share Your Photography” due to low engagement. We are looking at modern approaches to engage members.'
            )}
          </p>
          <p className="text-md leading-md">
            {t(
              'page.discontinued.page-description-2',
              'We’re sorry for any inconvenience this may cause and appreciate your understanding.'
            )}
          </p>
        </div>
      </div>
    </OneColumn>
  );
};

export default DiscontinuedPageComponent;
